import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
export default () => (
    <StaticQuery
        query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
        render={data => (
            <div className="hero-header">
                <div className="headline">{data.site.siteMetadata.home.title}</div>
                <div
                    className="primary-content"
                    dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.home.description }}
                />
                <Link to='/get-free-python-in-100-chunks-guide' className="button -primary font-bold">FREE Python Guide &rarr;</Link>
            </div>
        )}
    />
)