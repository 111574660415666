import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"

const IndexPage = ({
    data: {
        site,
        allMarkdownRemark: { edges },
    },
}) => {

    const Posts = edges
        .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
        .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

    return (
        <Layout>
            <Helmet>
                <title>{site.siteMetadata.title}</title>
                <meta name="description" content={site.siteMetadata.description} />
                <meta name="google-site-verification" content="5QDuLg013-WGmpbn0yik87aypgGJlUntwN7yGzXHNts" />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat" rel="stylesheet" />
            </Helmet>
            <HeroHeader />
            <h2>My Articles &darr;</h2>
            <div className="grids">
                {Posts}
            </div>
        </Layout>
    )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`
